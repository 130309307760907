import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/Seo"

import { SecondaryHero } from "../../components/Heros"

const TermsAndConditions = () => (
  <Layout>
    <Seo title="Sorted | Terms &amp; Conditions" keywords={[`Sorted`, `Products`, `Hero`, `React`, `Pro`]} />
    <SecondaryHero theme="pink" nav>
      <h1 class="text-center text-white underline display"><span>Terms and Conditions</span></h1>
    </SecondaryHero>
    <div class="container">
      <p>These terms of use govern the use by you (you or your) of our website, sorted.com (the Website), operated by Sorted limited (we, us, our).</p>
      <p>We are registered in England and Wales under company number 08609014 and our registered office is at Third Floor, Merchant Exchange, 17 - 19 Whitworth Street West, Manchester, England, M1 5WG.</p>
      <h3>1. Accessing the Website</h3>
      <p>1.1 Access to the Website is permitted when available and we will not be liable if for any reason our Website is unavailable, in whole or in part, for any reason, at any time or for any period.</p>
      <p>1.2 From time to time, we may restrict access to some parts of the Website, or the entire Website at our sole discretion.</p>
      <p>1.3 You are responsible for making all arrangements necessary for you to have access to the Website. You are also responsible for ensuring that all persons who access the Website through your internet connection are aware of these terms, and that they comply with them.</p>
      <h3>2. Intellectual property rights</h3>
      <p>2.1 All material accessible on the Website is protected by copyright. You may view Website pages on screen and may print or download extracts of them for your own personal use or internal circulation within your organisation.</p>
      <p>2.2 No part of the Website may be reproduced, transmitted to, or stored on any other website or in any other medium without our express written consent.</p>
      <p>2.3 You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</p>
      <p>2.4 If you print off, copy or download any part of our Website in breach of these terms, your right to use our Website and any materials obtained from it will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</p>
      <h3>3. Content of the Website</h3>
      <p>3.1 The content of the Website is for information purposes only and you acknowledge that it does not constitute advice on which reliance should be placed.</p>
      <p>3.2 Information is provided on an "as is" and "as available" basis and we do not give any express or implied promises about the accuracy, completeness, timeliness or usefulness of that information. The use of the Website is at your sole risk and our liability for any loss or damage whatsoever and howsoever arising as a result of your use or reliance on the information shall be excluded to the maximum extent permitted by law. This does not affect your statutory rights.</p>
      <p>3.3 Any descriptions of the features and functionality of our services are provided to give you an overview of those services. However, the exact features and functionality of any services that you receive from us will depend upon the outcome of any commercial discussions and negotiations and nothing on this Website should be seen as a representation of the features and functionality that you will actually receive if you choose to obtain services from us.</p>
      <p>3.4 If you do choose to obtain any service from us it will be subject to separate terms and conditions which you and we must agree to prior to the service commencing. Nothing on this Website represents an offer to provide any service to you, and we reserve the right to accept or refuse any order for our services in our absolute discretion.</p>
      <p>3.5 The Website includes content, services and hypertext links to websites owned, operated, controlled and/or provided by third parties (Third Party Content). Your use of any Third Party Content may be subject to the relevant third party’s terms and conditions, and it is your responsibility to make yourself aware of those terms and conditions. We will not necessarily see or be aware of any enquiries that you submit to any third parties who provide Third Party Content.</p>
      <p>3.6 We have no control over or proprietary interest in any Third Party Content. We do not give any promises about the quality, security, accuracy or any other aspect of any Third Party Content, and exclude any and all liability arising from your use of it.</p>
      <h3>4. Changes to the Website</h3>
      <p>4.1 We aim to update our Website regularly. However, please note that any of the content on our Website may be out of date at any given time, and we are under no obligation to update it.</p>
      <p>4.2 We reserve the right to do any of the following, at any time, without notice:</p>
      <p>4.2.1 to modify, suspend or terminate operation of or access to the Website, or any portion of the Website, for any reason;</p>
      <p>4.2.2 to modify or change the Website, or any portion of the Website and any applicable policies or terms; and</p>
      <p>4.2.3 to interrupt the operation of the Website, or any portion of the Website as necessary to perform routine or non-routine maintenance, error correction, or other changes.</p>
      <p>4.3 We assume no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorised access to, or alterations of user communications. We are not responsible for any technical malfunction or other problems of any nature including those involving a telephone network or service, computer systems, servers or providers, computer or mobile phone equipment, software or failure of email howsoever arising.</p>
      <h3>5. Links to the Website</h3>
      <p>5.1 You may not create a link to the Website from any other website unless you have our prior written consent, except where there is a function of our Website which specifically invites you to share a link to our content on other websites.</p>
      <p>5.2 In linking to the Website from another website (the Linking Website) you must not:</p>
      <p>5.2.1 replicate the Website in any way;</p>
      <p>5.2.2 give the impression that we recommend any properties, products or services other than our own;</p>
      <p>5.2.3 misrepresent the relationship between the Linking Website and us or present any other false information about us;</p>
      <p>5.2.4 impose any border environment or frame around the Website;</p>
      <p>5.2.5 use any of the trade names, marks or materials accessible on the Website without our express written permission; or</p>
      <p>5.2.6 create a link to the Website from a website that is not owned or controlled by you (except where using a function of our Website which allows you to share a link to our content on other websites).</p>
      <p>5.3 We reserve the right to refuse permission to link to the Website or to withdraw permission that has previously been granted at any time, acting in our absolute discretion.</p>
      <h3>6. Prohibited uses</h3>
      <p>6.1 You may use our Website only for lawful purposes. You must not:</p>
      <p>6.1.1 use our Website in any way that breaches any applicable local, national or international law or regulation;</p>
      <p>6.1.2 use our Website in any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect;</p>
      <p>6.1.3 use automate scripts to collect information from or otherwise interact with the Website; and</p>
      <p>6.1.4 knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.</p>
      <h3>6.2 You also agree:</h3>
      <p>6.2.1 not to reproduce, duplicate, copy or re-sell any part of our Website in contravention of the provisions of these terms;</p>
      <p>6.2.2 not to access without authority, interfere with, damage or disrupt:</p>
      <p>6.2.2.1 any part of our Website;</p>
      <p>6.2.2.2 any equipment or network on which our Website is stored;</p>
      <p>6.2.2.3 any software used in the provision of our Website; or</p>
      <p>6.2.2.4 any equipment or network or software owned or used by any third party.</p>
      <p>6.3 You warrant that you will not use the Website in any of the ways prohibited in this clause 6, and you agree that you will be liable to us and indemnify us for any breach of that warranty.</p>
      <h3>7. Our liability</h3>
      <p>7.1 The material on the Website is provided without any guarantees, conditions or warranties as to its accuracy. To the extent permitted by law, we hereby expressly exclude all conditions, warranties and other terms which might otherwise be implied by statute, common law or the law of equity.</p>
      <p>7.2 Any material downloaded or otherwise obtained through the use of this Website is done at your own discretion and risk and that you will be solely responsible for any damage to your own computer system or loss of data that results from the download of any such material.</p>
      <p>7.3 Nothing in these terms shall not operate so as to exclude, limit or restrict our liability for death or personal injury, fraud or fraudulent misrepresentation or for any other liability the exclusion or limitation of which is not permitted by English law.</p>
      <h3>8. General</h3>
      <p>8.1 We reserve the right to change these terms at any time without prior notice to you. Please check this page from time to time to take notice of any changes we made, as they are binding on you.</p>
      <p>8.2 If any provision of these terms shall be unlawful, void or for any reason unenforceable then that provision shall be deemed severable and shall not affect the validity and enforceability of the remaining provisions.</p>
      <p>8.3 Any dispute, controversy, proceedings or claim of whatever nature arising out of or in any way relating to these terms shall be governed and construed in accordance with English law. You and we hereby accept the exclusive jurisdiction of the English courts.</p>
    </div>
  </Layout>
)

export default TermsAndConditions
